<template>
  <div class="card">
    <div class="card-header">
      <div class="utils__title">
        <strong>Dopravy - seznam</strong>
      </div>
      <action-tools :router-options="routerOptions" :handle-refresh="handleRefresh"></action-tools>
    </div>
    <div class="card-body">
      <div class="table-operations">
        <language-tab></language-tab>
      </div>
      <div class="row mb-3">
        <div class="col-md-6">
          <a-dropdown :placement="'bottomLeft'">
            <a-button>Hromadné akce
              <a-icon type="down"/>
            </a-button>
            <a-menu slot="overlay">
              <a-menu-item :disabled="selectedRowKeys.length === 0" :class="{'disabled': selectedRowKeys.length === 0}">
                <a-popconfirm @confirm="confirmDelete(selectedRowKeys)" okText="Potvrdit" cancelText="Zrušit">
                  <template slot="title">
                    <p>Opravdu si přejete deaktivovat tyto položky?</p>
                    <div v-if="selectedRowKeys.length > 0">
                      {{ selectedRowKeys }}
                    </div>
                  </template>
                  Deaktivovat
                </a-popconfirm>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </div>
        <div class="text-right col-md-6">
          <a-input-search
            placeholder="Hledat..."
            style="width: 200px"
            @search="handleSearch"
          />
        </div>
      </div>
      <a-table
        tableLayout="auto"
        :rowSelection="rowSelection"
        :rowKey="record => record.dly_id"
        :columns="columns"
        :loading="loading"
        :dataSource="deliveries"
        class="utils__scrollTable"
        :scroll="{ x: '100%' }"
      >
        <router-link
          slot="dly_id"
          slot-scope="value"
          class="utils__link--underlined"
          :to="'/ecommerce/delivery/detail/' + value"
        >#<span v-if="searchText">
            <template
              v-for="(fragment, i) in value.toString().split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))">
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
              >{{fragment}}</mark>
              <template v-else>{{fragment}}</template>
            </template>
          </span>
          <template v-else>{{value}}</template>
        </router-link>
        <template slot="customRender" slot-scope="text">
          <a-alert v-if="text === undefined" message="není nastaveno" type="warning" showIcon />
          <span v-else-if="searchText">
            <template
              v-for="(fragment, i) in text.toString().split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))">
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
              >{{fragment}}</mark>
              <template v-else>{{fragment}}</template>
            </template>
          </span>
          <template v-else>{{text}}</template>
        </template>
        <span
          slot="image"
          slot-scope="record"
          :class="$style.thumbnail"
        >
          <img v-if="record !== null" :src="$uploadImagePath + record.iae_path + getImageSize(record, 1) + '.' + record.iae_format">
          <a-icon v-else theme="twoTone" twoToneColor="#ccc" type="frown" />
        </span>
        <span
          slot="active"
          slot-scope="value"
          :class="[value === undefined ? '' : value ? 'font-size-12 badge badge-success' : 'font-size-12 badge badge-danger']"
        >
          <span v-if="searchText">
            <template v-for="(fragment, i) in (value ? 'ano' : 'ne').toString().split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))" >
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
              >{{fragment}}</mark>
              <template v-else>{{fragment}}</template>
            </template>
          </span>
          <template v-else>{{value ? 'ano' : 'ne'}}</template>
        </span>
        <span
          slot="dates"
          slot-scope="text"
        >
          <span v-if="searchText">
            <template v-for="(fragment, i) in toLocaleDate(text).toString().split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))" >
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
              >{{fragment}}</mark>
              <template v-else>{{fragment}}</template>
            </template>
          </span>
          <template v-else>{{toLocaleDate(text)}}</template>
        </span>
        <span slot="action" slot-scope="record">
          <router-link :to="'/ecommerce/delivery/detail/'+record.dly_id">
            <a-button icon="search" size="small" class="mr-1">Zobrazit</a-button>
          </router-link>
          <a-popconfirm @confirm="confirmDelete([record.dly_id])" okText="Potvrdit" cancelText="Zrušit" v-if="record.dly_is_active">
            <template slot="title">
              <p>Opravdu si přejete deaktivovat položku: #{{ record.dly_id }} ?</p>
            </template>
            <a-button icon="stop" size="small">Deaktivovat</a-button>
          </a-popconfirm>
          <a-popconfirm @confirm="confirmActivate([record.dly_id])" okText="Potvrdit" cancelText="Zrušit" v-else>
            <template slot="title">
              <p>Opravdu si přejete aktivovat položku: #{{ record.dly_id }} ?</p>
            </template>
            <a-button icon="check" size="small">Aktivovat</a-button>
          </a-popconfirm>
        </span>
        <template slot="footer">
          {{ deliveries.length }} položek
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import ActionTools from '@/components/MyComponents/ActionTools'
import LanguageTab from '@/components/MyComponents/LanguageTab'
import imageSizeMixin from '@/components/MyComponents/Gallery/image-size-mixin'

export default {
  mixins: [imageSizeMixin],
  components: { ActionTools, LanguageTab },
  data: function () {
    return {
      image: {
        errors: [],
      },
      searchText: '',
      loading: false,
      selectedRowKeys: [],
      routerOptions: [
        {
          icon: 'plus-circle',
          to: '/ecommerce/delivery/add',
          title: 'Přidat',
        },
      ],
      columns: [
        {
          title: 'ID',
          dataIndex: 'dly_id',
          defaultSortOrder: 'ascend',
          sorter: (a, b) => a.dly_id - b.dly_id,
          scopedSlots: {
            customRender: 'dly_id',
          },
        },
        {
          title: 'Obrázek',
          dataIndex: 'image',
          scopedSlots: {
            customRender: 'image',
          },
        },
        {
          title: 'Název',
          dataIndex: 'current_history.languages[0].pivot.dhe_name',
          sorter: (a, b) => {
            return a.current_history.languages[0].pivot.dhe_name.localeCompare(b.current_history.languages[0].pivot.dhe_name)
          },
          scopedSlots: {
            customRender: 'customRender',
          },
        },
        {
          title: 'Sčítat ceny',
          dataIndex: 'current_history.dhy_is_sum_weight_price',
          sorter: (a, b) => a.current_history.dhy_is_sum_weight_price - b.current_history.dhy_is_sum_weight_price,
          scopedSlots: {
            customRender: 'active',
          },
        },
        {
          title: 'Aktivní',
          dataIndex: 'dly_is_active',
          sorter: (a, b) => a.dly_is_active - b.dly_is_active,
          scopedSlots: {
            customRender: 'active',
          },
        },
        {
          title: 'Výdejní místo',
          dataIndex: 'is_pickup',
          sorter: (a, b) => a.is_pickup - b.is_pickup,
          scopedSlots: {
            customRender: 'active',
          },
        },
        {
          title: 'Poslední úprava',
          dataIndex: 'current_history.dhy_date_from',
          sorter: (a, b) => new Date(a.current_history.dhy_date_from) - new Date(b.current_history.dhy_date_from),
          scopedSlots: {
            customRender: 'dates',
          },
        },
        {
          title: 'Akce',
          key: 'action',
          scopedSlots: {
            customRender: 'action',
          },
        },
      ],
    }
  },
  computed: {
    deliveries: function () {
      return this.$store.getters['delivery/currentLanguage'](this.searchText)
    },
    language: function () {
      return this.$store.getters['language/active']
    },
    rowSelection() {
      const { selectedRowKeys } = this
      return {
        selectedRowKeys,
        onChange: this.onSelectChange,
        hideDefaultSelections: true,
        selections: [{
          key: 'all-data',
          text: 'Vybrat všechny položky',
          onSelect: () => {
            this.selectedRowKeys = this.selectedRowKeys.length === 0 ? this.deliveries.map(item => item.dly_id) : []
          },
        }],
        onSelection: this.onSelection,
      }
    },
  },
  methods: {
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    toLocaleDate: function (text) {
      return this.$options.filters.formatDate(text)
    },
    handleRefresh() {
      this.loading = true
      this.$store.dispatch('delivery/getList')
        .catch(() => {})
        .finally(() => {
          this.loading = false
        })
    },
    confirmActivate(id) {
      this.loading = true
      this.$store.dispatch('delivery/activate', id)
        .catch(() => {})
        .finally(() => {
          this.loading = false
        })
    },
    confirmDelete(deliveries) {
      this.loading = true
      this.$store.dispatch('delivery/deactivate', deliveries)
        .then(() => {
          if (this.selectedRowKeys.length > 0) {
            this.selectedRowKeys = deliveries.filter(x => !this.selectedRowKeys.includes(x))
              .concat(this.selectedRowKeys.filter(x => !deliveries.includes(x)))
          }
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false
        })
    },
    handleSearch(searchText) {
      this.searchText = searchText
    },
  },
  created() {
    if (this.deliveries.length === 0 && this.language !== null) {
      this.handleRefresh()
    }
  },
  watch: {
    language(newValue, oldValue) {
      if (this.language !== null && this.deliveries.length === 0) {
        this.handleRefresh()
      }
    },
  },
}
</script>

<style lang="scss" module scoped>
  @import "./style.module.scss";
</style>
